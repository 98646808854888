import { RegisterAppUpdate, useTrackLogin } from '@insight2profit/drive-app';
import { CssBaseline } from '@mui/material';
import { AppRoutes } from 'routing';

export default function App() {
    useTrackLogin();

    return (
        <>
            <CssBaseline />
            <RegisterAppUpdate />
            <AppRoutes />
        </>
    );
}
