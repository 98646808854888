import { AuthenticationResult, EventMessage, EventType, IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Typography } from '@mui/material';
import { ClientId, LoginPermissionKey } from '../constants';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from '../startup';
import { useAuthState } from '../stores';
import { InsightB2CClaims } from '../types';
import { getAppPermissions, getUserInfoFromIdClaims } from '../utility';
import { isEqual } from 'lodash';

type AuthProviderProps = {
    clientApp: IPublicClientApplication;
    unauthorizedRoute: string;
    loginPermission: `${ClientId}.${LoginPermissionKey}`;
    clientId: string;
    appId: string;
};

export function ProvideAuth({
    children,
    clientApp,
    unauthorizedRoute,
    loginPermission,
    clientId,
    appId,
}: React.PropsWithChildren<AuthProviderProps>) {
    const navigate = useNavigate();
    const authState = useAuthState();
    const handleError = useErrorHandler();
    const { enqueueSnackbar } = useSnackbar();
    const dataAlreadySaved = useRef(false);

    React.useEffect(() => {
        const navigationClient = new CustomNavigationClient(navigate);
        clientApp.setNavigationClient(navigationClient);
        const accounts = clientApp.getAllAccounts();
        if (accounts.length > 0 && !dataAlreadySaved.current) {
            const account = accounts[0];
            clientApp.setActiveAccount(account);
            const user = getUserInfoFromIdClaims(account.idTokenClaims as InsightB2CClaims);
            const appPermissions = getAppPermissions(user.insightClaims, clientId);
            if (!appPermissions.includes(loginPermission)) {
                navigate(unauthorizedRoute);
            }

            authState.user.set(user);
            dataAlreadySaved.current = account?.idTokenClaims?.aud === appId;
        }

        const callbackId = clientApp.addEventCallback((event: EventMessage) => {
            switch (event.eventType) {
                case EventType.LOGIN_SUCCESS:
                    const payload = event.payload as AuthenticationResult;
                    // Verify Insight claims here.
                    const account = payload.account;
                    clientApp.setActiveAccount(account);
                    const user = getUserInfoFromIdClaims(account?.idTokenClaims as InsightB2CClaims);
                    const appPermissions = getAppPermissions(user.insightClaims, clientId);
                    if (!appPermissions.includes(loginPermission)) {
                        navigate(unauthorizedRoute);
                    }
                    authState.user.set(user);
                    break;
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    const payload_token = event.payload as AuthenticationResult;
                    const account_token = payload_token.account;
                    const user_token = getUserInfoFromIdClaims(account_token?.idTokenClaims as InsightB2CClaims);

                    if (!isEqual(user_token, authState.user.get())) {
                        clientApp.setActiveAccount(account_token);
                        const appPermissions_token = getAppPermissions(user_token.insightClaims, clientId);
                        if (!appPermissions_token.includes(loginPermission)) {
                            navigate(unauthorizedRoute);
                        }

                        authState.user.set(user_token);
                        dataAlreadySaved.current = true;
                    }

                    break;
                case EventType.LOGOUT_END:
                    authState.user.set(null);
                    break;
                case EventType.LOGIN_FAILURE:
                case EventType.ACQUIRE_TOKEN_FAILURE:
                case EventType.LOGOUT_FAILURE:
                    break;
                default:
                    break;
            }
        });

        return () => {
            if (callbackId) {
                clientApp.removeEventCallback(callbackId);
            }
        };
    }, [clientApp, history, authState, handleError, enqueueSnackbar]);

    return <MsalProvider instance={clientApp}>{children}</MsalProvider>;
}

export const AuthInProgress = () => (
    <Typography sx={{ textAlign: 'center' }} variant='h4'>
        Authorization in progress...
    </Typography>
);
