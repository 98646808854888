import { AppPermission, CLIENT_ID, PAGE_PERMISSIONS } from '../constants';
import { PageKey, PagePermissionStatus } from '../types';
import { getAppPermissions } from '../utility';
import { useUser } from './use-user';

export function usePermission(): PagePermissionStatus {
    const user = useUser();
    const claims = user?.insightClaims || '';
    const permissions: AppPermission<string>[] = getAppPermissions(claims, CLIENT_ID);

    const pagePermissions = Object.entries(PAGE_PERMISSIONS).reduce(
        (result, [page, { edit, view }]) => ({
            ...result,
            [page as PageKey]: {
                edit: permissions.includes(edit),
                view: permissions.includes(view),
            },
        }),
        {}
    );

    return pagePermissions as PagePermissionStatus;
}
