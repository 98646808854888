import { LevelPermission, PagePermission } from '../types';
import { AppPermission, LoginPermissionKey } from './app';

type PermissionKey = LoginPermissionKey;

export const PERMISSIONS: Record<PermissionKey, AppPermission<PermissionKey>> = {
    login: 'kdc-one.login',
};

export const PAGE_PERMISSIONS: PagePermission = {
    customerInformationPermission: {
        edit: 'kdc-one.customer-information.edit',
        view: 'kdc-one.customer-information.view',
    },
    productInformationPermission: {
        edit: 'kdc-one.product-information.edit',
        view: 'kdc-one.product-information.view',
    },
    rmCostPermission: {
        edit: 'kdc-one.rm-cost.edit',
        view: 'kdc-one.rm-cost.view',
    },
    componentPermission: {
        edit: 'kdc-one.component.edit',
        view: 'kdc-one.component.view',
    },
    directLaborPermission: {
        edit: 'kdc-one.direct-labor.edit',
        view: 'kdc-one.direct-labor.view',
    },
    additionalFeesPermission: {
        edit: 'kdc-one.fees.edit',
        view: 'kdc-one.fees.view',
    },
    pricingAndApprovalPermission: {
        edit: 'kdc-one.pricing-and-approval.edit',
        view: 'kdc-one.pricing-and-approval.view',
    },
    quoteDrillDashboardPermission: {
        edit: 'kdc-one.quote-drill.edit',
        view: 'kdc-one.quote-drill.view',
    },
    approvalRequestPermission: {
        edit: 'kdc-one.approval-request.edit',
        view: 'kdc-one.approval-request.view',
    },
} as const;

export const LEVEL_PERMISSIONS: LevelPermission = {
    level1costing: {
        edit: 'kdc-one.approval-level.1-costing',
    },
    level2costing: {
        edit: 'kdc-one.approval-level.2-costing',
    },
    level3decision: {
        edit: 'kdc-one.approval-level.3',
    },
    level4decision: {
        edit: 'kdc-one.approval-level.4',
    },
    level5decision: {
        edit: 'kdc-one.approval-level.5',
    },
} as const;
