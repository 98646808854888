export const CLIENT_ID = 'kdc-one' as const;
export type ClientId = typeof CLIENT_ID;

export type LoginPermissionKey = 'login';
export type AppPermission<T extends string> = `${ClientId}.${T}`;

export const MUI_LICENSE_KEY =
    '986a355507e186e481e1084cc8541ec8Tz04OTQwNyxFPTE3NDYwMzUyNjEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'; // updated 2024-04-29

export const SUPPORT_PORTAL_URL = 'https://insight2profit.atlassian.net/servicedesk/customer/portal/30';
