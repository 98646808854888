import { ObjectTyped } from '@price-for-profit/micro-services';
import { ParamParseKey } from 'react-router-dom';

export const DEFAULT_ROUTES = {
    login: '/',
    unauthorized: '/unauthorized',
    demo: '/demo',
    getPath<P extends string>({ path, params }: GetPathProps<P>) {
        if (!params) return path;
        return ObjectTyped.entries(params).reduce<string>((pathToGo, [param, value]) => {
            return pathToGo.replace(`:${param}`, value);
        }, path);
    },
} as const;

type GetPathProps<P extends string> = _PathParam<P> extends never
    ? {
          path: P;
          params?: never;
      }
    : {
          path: P;
          params: Record<ParamParseKey<P>, string>;
      };
// taken from react-router-dom's types (utils.d.ts) to determine if a path has route params or not.
type _PathParam<Path extends string> = Path extends `${infer L}/${infer R}`
    ? _PathParam<L> | _PathParam<R>
    : Path extends `:${infer Param}`
    ? Param extends `${infer Optional}?`
        ? Optional
        : Param
    : never;
