import { Dashboard } from '@mui/icons-material';
import { I2PEnvironment } from '@price-for-profit/micro-services';
import { usePermission } from '../../hooks';
import { PageKey as PageKeyPermission } from '../../types';

const routeConfig: EnvironmentNavigation[] = [
    {
        application: {
            applicationName: 'KDC One Drive Account',
            env: {
                local: {
                    applicationId: '0ede169c-811a-4b63-b422-b6fb9e951400',
                    deployedUrl: 'https://localhost:5001/apps/account',
                },
                dev: {
                    applicationId: '0ede169c-811a-4b63-b422-b6fb9e951400',
                    deployedUrl: 'https://kdc-one.dev.insight2profit.com/apps/account',
                },
                sandbox: {
                    applicationId: '8a5582a6-ece2-49b4-a5b8-2210c5fece65',
                    deployedUrl: 'https://kdc-one.sandbox.insight2profit.com/apps/account',
                },
                prod: {
                    applicationId: '6a14b9ad-44e8-43e3-a1a0-f0871f2b8814',
                    deployedUrl: 'https://kdc-one.insight2profit.com/apps/account',
                },
            },
        },
        links: [
            {
                title: 'Analytics Dashboard',
                url: '/analytics',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
    {
        application: {
            applicationName: 'KDC One Quote Drive Application',
            env: {
                local: {
                    applicationId: 'b4cd1cee-785d-48ea-9566-829d2e47650a',
                    deployedUrl: 'https://localhost:5003/apps/quote',
                },
                dev: {
                    applicationId: 'b4cd1cee-785d-48ea-9566-829d2e47650a',
                    deployedUrl: 'https://kdc-one.dev.insight2profit.com/apps/quote',
                },
                sandbox: {
                    applicationId: '243b507d-1016-4537-89e8-69b8aca87288',
                    deployedUrl: 'https://kdc-one.sandbox.insight2profit.com/apps/quote',
                },
                prod: {
                    applicationId: 'b99f7200-6b20-41ce-b7d4-a3e906bfc025',
                    deployedUrl: 'https://kdc-one.insight2profit.com/apps/quote',
                },
            },
        },
        links: [
            {
                title: 'Quote Dashboard',
                url: '/quote-dashboard',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
            {
                title: 'Status Dashboard',
                url: '/status-dashboard',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
            {
                title: 'Approval Request Dashboard',
                url: '/approval-request-dashboard',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
                permission: 'approvalRequestPermission',
            },
        ],
    },
];

interface NavigationLink {
    title: string;
    url: string;
    icon?: JSX.Element;
    permission?: PageKeyPermission;
}

interface EnvironmentNavigation {
    application: {
        applicationName: string;
        env: Record<Exclude<I2PEnvironment, 'model'> | 'local', { applicationId: string; deployedUrl: string }>;
    };
    links: NavigationLink[];
}

interface DriveAccountNavigation {
    application: {
        applicationId: string;
        applicationName: string;
        deployedUrl: string;
    };
    links: NavigationLink[];
    local?: boolean;
}

interface BuildDriveAccountNavigationProps {
    applicationId: string;
    environment: Exclude<I2PEnvironment, 'model'>;
    useLocal?: boolean;
}

export function buildDriveAccountNavigation({
    applicationId,
    environment,
    useLocal,
}: BuildDriveAccountNavigationProps): DriveAccountNavigation[] {
    const localEnvironment = environment === 'dev' && useLocal ? 'local' : environment;
    const permissions = usePermission();

    return routeConfig.map<DriveAccountNavigation>(({ links, application }) => {
        const envConfig = application.env[localEnvironment];

        return {
            application: {
                applicationName: application.applicationName,
                ...envConfig,
            },
            links: links.filter(link => {
                return !link.permission || permissions[link.permission].view;
            }),
            local: applicationId === envConfig.applicationId,
        };
    });
}

export const showHamburger = routeConfig.length > 1;
